* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home,
.skills,
.projects,
.resume {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.resume_site {
  /* padding: 60px 100px 200px 100px; */
  padding: 2rem 3rem;

  /* @media screen and (max-width: 468px) {
    padding: 60px 100px 200px 100px;
  } */
}
.resume_site h1 {
  font-size: 26px;
  text-align: left;
  margin-bottom: 10px;
}
.resume_site span {
  font-weight: 800;
  text-align: left;
  font-size: 22px;
  color: #808080;
}
.resume_site p {
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  color: #808080;
}
.live_site {
  color:black;
}
.live_site span {
  color:#fff;
  /* padding-top: 5px; */
  background-color: #1f98f4;
 border-radius: 10px;
 padding: 6px;
 font-size: 12px;
 font-weight: bold;
 border: 1px solid #1f98f4;

}
.live_site span:hover {
  color: #000;
   background-color: #fff;
   border: 1px solid #1f98f4;
   border-radius: 10px;
   

}
.live_site:hover {
  /* background-color: #16a1e7; */
  /* border-radius: 15px; */
  
  
  
}




/* .live_site a:link {
  color:black;
}
.live_site a:hover {
  color: yellow;
} */
.skills {
  background-image: url("./assets/img-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.projects {
  background-image: url("./assets/img-home.jpg");
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.portfolio__links {
  display: flex;
  justify-content: center;
font-size: 20px;

}
.portfolio_thumbnails {
  background: url("/src/assets/img-bio3.jpg") center center/cover no-repeat;
  margin: 10px;
    border: 0px solid black;
    border-radius: 10px;
    overflow: hidden;
    transform: scale(1.07);
    box-shadow: 0px 6px 18px -9px rgba(0,0,0,0.75);
    transition: transform 100ms ease-in;
    cursor: pointer;
    
}
.portfolio_thumbnails > img {
  object-fit: fill;
  min-width: 300px;
    min-height: 200px;
    width: 100%;
}
.bio__copy {
  width: 50vw;
  /* padding-top: 10px; */
  padding-left: 10px;
  
}
.bio__copy > h2{
 font-size: 30px;
 font-weight: 600;
 margin-top: -5px;
  
}
.bio__copy > p{
  font-size: 13px;
  color: 	#808080;
  margin-top: 10px;
  padding-right: 30px;
  /* margin-right: 40px; */
 }
 
 .bio__copy > ul{
  font-size: 13px;
  color: 	#808080;
  padding-left: 15px;
   
 }

 .skillset__copy {
  color: 	#808080;
  font-size: 14px;
  text-align: center;
  padding-bottom: 30px;

 }

 .skills__ui {
   display: flex;
   justify-content: center;
   padding-bottom: 40px;
 }
 .skills__ui > img {
   width: 10vw;
   object-fit: contain;
   /* height: 300px; */
 }

 .container-skills {
  width: 100%;
   display: flex;
   flex-wrap: wrap;
   /* justify-content: space-evenly; */
   justify-content: center;
   /* justify-content: space-between; */
   
 }
 .container-skills2 {
  width: 100%;
   display: flex;
   flex-wrap: wrap;
   /* justify-content: space-evenly; */
   justify-content: center;
   /* justify-content: space-between; */
   margin-top: 50px;
   margin-bottom: 50px;
   
 }
 .content-skills {
   
   /* margin-right: 70px; */
   /* margin-left: 70px; */
   display: flex;
   padding-right: 50px;
   padding-left: 50px;
   flex-direction: column;
   align-items: center;
   
   
 }
 .send_button {
   /* display: flex; */
   font-size: 20px;
   width: 100px;
   padding: 6px;
 }
 .tools-style {
   margin-top: 40px;
   margin-bottom: 60px;
 }
 
 .js__thumb1 {
   width: 60px;
   height: 60px;
   background-image: url('assets/javascript-original.svg');
   
 }
 .js__thumb2 {
   width: 60px;
   height: 60px;
   background-image: url('assets/react-original.svg');
   
 }
 .js__thumb3 {
   width: 60px;
   height: 60px;
   background-image: url('assets/redux-original.svg');
   
 }
 
 .js__thumb4 {
   width: 60px;
   height: 60px;
   background-image: url('assets/nodejs-original.svg');
   
 }
 .js__thumb5 {
   width: 60px;
   height: 60px;
   background-image: url('assets/html5-original.svg');
   
 }
 .js__thumb6 {
   width: 60px;
   height: 60px;
   background-image: url('assets/css3-original.svg');
   
 }
 .js__thumb7 {
   width: 60px;
   height: 60px;
   background-image: url('assets/bootstrap-plain.svg');
   
 }
 .js__thumb8 {
   width: 60px;
   height: 60px;
   background-image: url('assets/sass-original.svg');
   
 }
 .js__thumb10 {
   width: 60px;
   height: 60px;
   background-image: url('assets/visualstudio-plain.svg');
   
 }
 .js__thumb11 {
   width: 60px;
   height: 60px;
   background-image: url('assets/git-original.svg');
   
 }
 .js__thumb12 {
   width: 60px;
   height: 60px;
   background-image: url('assets/github-original.svg');
   
 }
 
 .js__thumb13 {
   width: 60px;
   height: 60px;
   background-image: url('assets/npm-original.svg');
   
 }
 .js__thumb22 {
   width: 60px;
   height: 60px;
   background-image: url('assets/mongodb-icon.svg');
   object-fit: contain;
   
 }
 #skills {
   padding-top: 30px;
   
 }
 #tools {
   margin-top: 20px;
   margin-bottom: 80px;
 }
 #h2tools {
   /* margin-top: 50px; */
   padding-top: 80px;
 }
 
 .circle__icon {
   display: flex;
   flex-direction: row;
   /* margin-top: -20px; */
 }
 .circle__icon > p {
   padding-top: 3px;
 }
 .span__bio {
   color: #079CE9
 }

 .hr__projects {
   margin-left: 80px;
   margin-right: 80px;
   
 }
 
 .span__link a:link {
  color: 	#808080;
   text-decoration: none;
   font-size: 14px;
 }
 .span__link a:hover {
   text-decoration: none;
   
 }
 .span__link a:visited {
  color: 	#808080;
   text-decoration: none;
 }

 .my__projects {
   font-size: 36px;
   text-align: center;
   border-top: 1px solid #808080;
   border-bottom: 1px solid #808080;
   box-shadow: 0 9px 30px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
   
   /* background-color: rgba(44, 0, 37, 0.2); */
   padding: 30px;
   opacity: 0.5;
 }
 
 
   @media screen and (max-width: 769px) {
    .bio__copy > h2 {
      font-size: 25px;
    font-weight: 600; 
    margin-left: 10px;
    margin-right: 10px;
    }
   .bio__copy {
    width: 90vw;
    margin-left: 15px; 
  }
  .bio__copy > p{
    font-size:18px;
    margin-right: 5px;
    padding-top: 0;
    margin-left: 10px;
  }
}


.resume {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.typed-text {
  font-size: 1.25rem;
  color: white;
  font-weight: 400;
  /* font-family: "Orbitron", sans-serif; */
  font-family: "PT Sans", sans-serif;
}
