:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}
.btn--bio {
    background-color: #404040;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #404040;
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}
.btn--small {
    padding: 5px 16px;
    font-size: 15px;
    margin-top: 10px;
}

.btn--medium:hover, .btn--large:hover, .btn--small:hover {
    background: white;
    color: #242424;
    transition: all 0.3s ease-out;

}

.btn-resume {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    text-align: center;
    text-decoration: none
}

.btn-resume:hover {
    background: white;
    color: #242424;
    transition: all 0.3s ease-out;
}
a:link {
    text-decoration: none;
    color: white;
    font-size: 20px;
}
a:active {
    text-decoration: none;
    color: white;
    font-size: 20px;
}
a:visited {
    text-decoration: none;
    color: white;
    font-size: 20px;
}
a:hover {
    text-decoration: none;
    color: #242424;
    font-size: 20px;
}
